<template>
    <UtilityButton @clicked="onClicked" icon="print" class="print-button"> </UtilityButton>
</template>

<script setup>
const onClicked = () => {
    if (process.client) {
        print();
    }
};
</script>

<style lang="scss" scoped></style>
